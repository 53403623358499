import React, { useState } from "react";
import AnimateSvg3dBall from "./AnimateSvg3dBall";
import { TextField, Button, useTheme, Slider, Box, Drawer } from "@mui/material"; // Import Material UI components
// import { darken, lighten } from "@mui/material";
import { PaletteColor } from "@mui/material";


interface Options {
    svgHeight?: number;
    svgWidth?: number;
    viewBoxHeight?: number;
    viewBoxWidth?: number;
    colorFillFrom?: string;
}

interface AnimateSvg3dBallWithMuiFormOnDrawerState {
    wrapStyle: React.CSSProperties;
    wrapClassname: string;
    options: Options;
}

const sliderMarks = [

    {
        value: 50,
        label: '50px',
    },
    {
        value: 250,
        label: '250px',
    },
    {
        value: 500,
        label: '500px',
    },
];

const AnimateSvg3dBallWithMuiFormOnDrawer: React.FC = () => {
    const [open, setOpen] = useState<boolean>(false);

    const [state, setState] = useState<AnimateSvg3dBallWithMuiFormOnDrawerState>({
        wrapStyle: {},
        wrapClassname: "clay",
        options: {
            svgHeight: 53.946306, // 203.89156,
            svgWidth: 53.946306, // 203.89156,
            viewBoxHeight: 53.946306, // 50,
            viewBoxWidth: 53.946306, // 190,
            // colorFillFrom: "#7fff00",
        },
    });

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    // Define a handler function to update the state based on user input
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleChangeOptionsNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setState((prevState) => ({
            ...prevState,
            options: {
                ...prevState.options,
                [name]: parseInt(value),
            },
        }));
    };


    const handleChangeOptionsNumberSlider = (event: any, newValue: number | number[]) => {
        const { name, value } = event.target;
        setState((prevState) => ({
            ...prevState,
            options: {
                ...prevState.options,
                [name]: newValue as number,
            },
        }));
    };

    // Define a handler function to update the state based on user color selection
    const handleColorChange = (name: string, color: PaletteColor) => {
        // Update the state accordingly
        setState((prevState) => ({
            ...prevState,
            options: {
                ...prevState.options,
                [name]: color.main,
            },
        }));
    };

    const theme = useTheme();

    // Define an array of named colors from the theme palette
    const colors = [
        { name: "primary", color: theme.palette.primary },
        { name: "primary2", color: theme.palette.primary2 },
        { name: "secondary", color: theme.palette.secondary },
        { name: "secondary2", color: theme.palette.secondary2 },
        { name: "error", color: theme.palette.error },
        { name: "warning", color: theme.palette.warning },
        { name: "info", color: theme.palette.info },
        { name: "success", color: theme.palette.success },
    ];

    return (
        <Box>
            <Button variant="contained" color="primary" onClick={handleDrawerOpen}
                sx={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '5px',
                    fontSize: ["small", "small"]

                }}
            >
                Nastavenia
            </Button>
            <Drawer
                anchor="right" // Set the drawer to appear on the right side
                open={open} // Set the drawer visibility based on the state variable
                onClose={handleDrawerClose} // Set the function to close the drawer
                PaperProps={{
                    sx: {
                        height: "auto", // Set the drawer height to auto
                        top: 0, // Set the drawer top position to zero
                        px: { xs: 1, sm: 8 },
                        py: { xs: "80px" },
                        borderRadius: 0.5,
                    },
                }}
            >
                {/* Use TextField components instead of input elements */}
                {/* <TextField
                    label="Wrap Style"
                    id="wrapStyle"
                    name="wrapStyle"
                    value={state.wrapStyle}
                    onChange={handleChange}
                /> */}
                <TextField
                    label="Wrap Classname"
                    id="wrapClassname"
                    name="wrapClassname"
                    value={state.wrapClassname}
                    onChange={handleChange}
                />
                <br />
                <Box sx={{
                    p: { xs: 0.5, sm: 2 }, border: '1px dashed grey',
                    display: 'flex', alignItems: 'center',
                    // width: '100%', justifyContent: 'space-between' 
                }}
                    key="svgHeight"
                >
                    <Slider
                        name="svgHeight"
                        value={state.options?.svgHeight || 50}
                        min={10}
                        max={100}
                        marks={sliderMarks}
                        onChange={handleChangeOptionsNumberSlider}
                        sx={{
                            flex: 1,
                            mx: "8px"
                        }}
                    />
                    <TextField
                        // label="SVG Height"
                        label="height"
                        id="svgHeight"
                        name="svgHeight"
                        type="number"
                        value={state.options?.svgHeight || 50}
                        onChange={handleChangeOptionsNumber}
                        sx={{
                            width: 80,
                            mx: "8px"
                        }}
                    />
                </Box>
                <Box sx={{
                    p: { xs: 0.5, sm: 2 }, border: '1px dashed grey',
                    display: 'flex', alignItems: 'center',
                }}
                    key="svgWidth"
                >
                    <Slider
                        name="svgWidth"
                        value={state.options?.svgWidth || 50}
                        min={10}
                        max={500}
                        marks={sliderMarks}
                        onChange={handleChangeOptionsNumberSlider}
                        sx={{
                            flex: 1,
                            mx: "8px"
                        }}
                    />
                    <TextField
                        // label="SVG Width"
                        label="width"
                        id="svgWidth"
                        name="svgWidth"
                        type="number"
                        value={state.options.svgWidth}
                        onChange={handleChangeOptionsNumber}
                        sx={{
                            width: 80,
                            mx: "8px"
                        }}
                    />
                </Box>
                <Box sx={{
                    p: { xs: 0.5, sm: 2 }, border: '1px dashed grey',
                    display: 'flex', alignItems: 'center',
                }}
                    key="viewBoxHeight"
                >
                    <Slider
                        name="viewBoxHeight"
                        value={state.options?.viewBoxHeight || 50}
                        min={10}
                        max={100}
                        marks={sliderMarks}
                        onChange={handleChangeOptionsNumberSlider}
                        sx={{
                            flex: 1,
                            mx: "8px"
                        }}
                    />
                    <TextField
                        // label="SVG viewBox Height"
                        label="vbHeight"
                        id="viewBoxHeight"
                        name="viewBoxHeight"
                        type="number"
                        value={state.options.viewBoxHeight}
                        onChange={handleChangeOptionsNumber}
                        sx={{
                            width: 80,
                            mx: "8px"
                        }}
                    />
                </Box>
                <Box sx={{
                    p: { xs: 0.5, sm: 2 }, border: '1px dashed grey',
                    display: 'flex', alignItems: 'center',
                }}
                    key="viewBoxWidth"
                >
                    <Slider
                        name="viewBoxWidth"
                        value={state.options?.viewBoxWidth || 50}
                        min={10}
                        max={500}
                        marks={sliderMarks}
                        onChange={handleChangeOptionsNumberSlider}
                        sx={{
                            flex: 1,
                            mx: "8px"
                        }}
                    />
                    <TextField
                        // label="SVG viewBox Width"
                        label="vb Width"
                        id="viewBoxWidth"
                        name="viewBoxWidth"
                        type="number"
                        value={state.options.viewBoxWidth}
                        onChange={handleChangeOptionsNumber}
                        sx={{
                            width: 80,
                            mx: "8px"
                        }}
                    />
                </Box>

                {/* Use Button components to let user pick colors for colorFillFrom */}
                <div>
                    <p>Vyber farbu:</p>
                    {colors.map((color) => (
                        <Button
                            key={color.name}
                            variant="contained"
                            style={{
                                backgroundColor: color.color.main,
                                aspectRatio: '1 / 1',
                                borderRadius: '50%',
                                padding: '0',
                                width: '50px',
                                minWidth: 'unset',
                                borderWidth: 'thick',
                                borderColor: "black",
                                borderStyle: color.color.main === state.options.colorFillFrom ? "ridge" : "unset",
                            }}
                            onClick={() => handleColorChange("colorFillFrom", color.color)}
                        >
                        </Button>
                    ))}
                </div>
            </Drawer>
            <AnimateSvg3dBall
                wrapStyle={state.wrapStyle}
                wrapClassname={state.wrapClassname}
                options={state.options}
            />
        </Box>
    );
};

export default AnimateSvg3dBallWithMuiFormOnDrawer;