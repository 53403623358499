import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { lighten, darken, alpha } from "@mui/material";
import { NotInViewContext } from "./AnimateScrollIntoViewport";
// import AnimationButton from "./AnimationButton";
import AnimationCycleTimeSlider from "./AnimationCycleTimeSlider";

const rainbowColors0 = [
    'hsla(1deg, 96%, 55%,50%)', // red
    'hsla(25deg, 100%, 50%,50%)', // orange
    'hsla(40deg, 100%, 50%,50%)', // yellow
    'hsla(45deg, 100%, 50%,50%)', // yellow
    'hsla(66deg, 100%, 45%,50%)', // lime
    'hsla(130deg, 100%, 40%,50%)', // green
    'hsla(177deg, 100%, 35%,50%)', // aqua
    'hsla(230deg, 100%, 45%,50%)', // blue
    'hsla(240deg, 100%, 45%,50%)', // indigo
    'hsla(260deg, 100%, 55%,50%)', // violet
    'hsla(325deg, 100%, 48%,50%)', // pink
];

const rainbowColors1 = [
    'hsla(25deg, 100%, 50%,50%)', // orange
    'hsla(40deg, 100%, 50%,50%)', // yellow
    'hsla(45deg, 100%, 50%,50%)', // yellow
    'hsla(66deg, 100%, 45%,50%)', // lime
    'hsla(130deg, 100%, 40%,50%)', // green
    'hsla(177deg, 100%, 35%,50%)', // aqua
    'hsla(230deg, 100%, 45%,50%)', // blue
    'hsla(240deg, 100%, 45%,50%)', // indigo
    'hsla(260deg, 100%, 55%,50%)', // violet
    'hsla(325deg, 100%, 48%,50%)', // pink
    'hsla(1deg, 96%, 55%,50%)', // red
];
const rainbowColors2 = [
    'hsla(40deg, 100%, 50%,50%)', // yellow
    'hsla(45deg, 100%, 50%,50%)', // yellow
    'hsla(66deg, 100%, 45%,50%)', // lime
    'hsla(130deg, 100%, 40%,50%)', // green
    'hsla(177deg, 100%, 35%,50%)', // aqua
    'hsla(230deg, 100%, 45%,50%)', // blue
    'hsla(240deg, 100%, 45%,50%)', // indigo
    'hsla(260deg, 100%, 55%,50%)', // violet
    'hsla(325deg, 100%, 48%,50%)', // pink
    'hsla(1deg, 96%, 55%,50%)', // red
    'hsla(25deg, 100%, 50%,50%)', // orange
];

const mapColors = { 0: rainbowColors0, 1: rainbowColors1, 2: rainbowColors2 }
const mapColorsReverted = { 0: [...rainbowColors0].reverse(), 1: [...rainbowColors1].reverse(), 2: [...rainbowColors2].reverse(), }
const colorKoef = 2

const AnimateSvg3dBall = ({ wrapStyle = {}, wrapClassname = '', options = {} }) => {
    const isMounted = useRef(false);
    const {
        svgHeight = 53.946306, // 203.89156,
        svgWidth = 53.946306, // 203.89156,
        viewBoxHeight = 53.946306, // 50,
        viewBoxWidth = 53.946306, // 190,
        colorFillFrom, // = "#7fff00",
    } = options

    const colorFillFromShades1 = colorFillFrom ? [
        lighten(colorFillFrom, 0.5),
        darken(colorFillFrom, 0.5)

    ] : []

    const colorFillFromShades0 = colorFillFrom ? [
        colorFillFrom,
        darken(colorFillFrom, 1)
    ] : []

    const colorFillFromShades2 = colorFillFrom ? [
        lighten(colorFillFrom, 1),
        colorFillFrom,
    ] : []


    const colorFillFromShadesRevert0 = [...colorFillFromShades0].reverse()
    const colorFillFromShadesRevert1 = [...colorFillFromShades1].reverse()
    const colorFillFromShadesRevert2 = [...colorFillFromShades2].reverse()

    const colorFillFromShades = [colorFillFromShades0, colorFillFromShades1, colorFillFromShades2]
    const colorFillFromShadesRevert = [colorFillFromShadesRevert0, colorFillFromShadesRevert1, colorFillFromShadesRevert2]

    const colorFillFromShades3 = colorFillFrom ? {
        50: lighten(colorFillFrom, 0.25),
        100: lighten(colorFillFrom, 0.2),
        200: lighten(colorFillFrom, 0.15),
        300: lighten(colorFillFrom, 0.1),
        400: lighten(colorFillFrom, 0.05),
        500: colorFillFrom,
        600: darken(colorFillFrom, 0.05),
        700: darken(colorFillFrom, 0.1),
        800: darken(colorFillFrom, 0.15),
        900: darken(colorFillFrom, 0.2),
        A100: colorFillFrom,
    } : {};


    const [replay, setReplay] = useState(true);
    const refWrap = useRef(null)
    const refAlreadyClicked = useRef(false)
    const { notInView, isCountedTwice } = useContext(NotInViewContext);
    const [duration, setDuration] = useState(3);

    const svgDistanceFor1Rotation = Math.PI * svgHeight
    const ctlSvg = useAnimation()
    const ctlSvgPartCircleClip = useAnimation()
    const ctlSvgPartCircle = useAnimation()

    const stopAllAnimation = useCallback(
        () => {
            ctlSvg.stop()
            ctlSvgPartCircleClip.stop()
            ctlSvgPartCircle.stop()
        },
        [ctlSvg, ctlSvgPartCircleClip, ctlSvgPartCircle])

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
    })

    useEffect(() => {
        const canAnimate = (!notInView && isCountedTwice) || refAlreadyClicked.current
        if (!canAnimate) {
            stopAllAnimation()
            return
        }
        const seq = async () => {

            isMounted.current && canAnimate && await ctlSvg.start(i => ({
                rotateX: [null, 45, 0],
                x: [0, 20, 0],
                transition: {
                    delay: i * 0.1,
                    type: "spring",
                    damping: 1,
                    stiffness: 10,
                }
            } as any))
            isMounted.current && canAnimate && await ctlSvgPartCircleClip.start({
                x: [0, 50, 0],
                transition: {
                    delay: 0,
                    type: "spring",
                    damping: 10,
                    stiffness: 100,
                }
            } as any)

            isMounted.current && canAnimate && ctlSvgPartCircle.start(i => ({
                fill: colorFillFrom ? colorFillFromShades[i] : [...mapColors[i]],
                transition: {
                    delay: i * 0.1,
                    duration //: 4,
                }
            } as any))


            isMounted.current && canAnimate && await ctlSvg.start(i => ({
                rotateZ: [null, 720, 0],
                scale: [null, 1.5, 1],
                z: [null, svgDistanceFor1Rotation * 2, 0],
                transition: {
                    delay: i * 0.1,
                    duration //: 4,
                }
            } as any))
            isMounted.current && canAnimate && ctlSvgPartCircle.start(i => ({
                fill: colorFillFrom ? colorFillFromShadesRevert[i] : [...mapColorsReverted[i]],
                transition: {
                    delay: i * 0.1,
                    duration //: 4,
                }
            } as any))

            isMounted.current && canAnimate && await ctlSvg.start(i => ({
                rotateZ: [null, 720, 0],
                x: [null, svgDistanceFor1Rotation * 2, 0],
                transition: {
                    delay: i * 0.1,
                    duration //: 4,
                }
            } as any))
            isMounted.current && canAnimate && ctlSvgPartCircle.start(i => ({
                fill: colorFillFrom ? colorFillFromShades[i] as any : [...mapColors[i]] as any,
                transition: {
                    delay: i * 0.1,
                    duration //: 4,
                }
            } as any))
            isMounted.current && canAnimate && await ctlSvg.start(i => ({
                rotateZ: [null, -720, 0],
                y: [null, -svgDistanceFor1Rotation * 2, 0],
                transition: {
                    delay: i * 0.1,
                    duration //: 4,
                }
            } as any))
        }
        seq()
        return () => {
            stopAllAnimation()
        }
    }, [notInView, replay, stopAllAnimation, colorFillFrom, ctlSvgPartCircleClip, ctlSvg, ctlSvgPartCircle, svgDistanceFor1Rotation])

    return (
        <Box
            ref={refWrap}
            className={wrapClassname}
            sx={{
                position: "relative",
                marginTop: 0,
                marginBottom: 0,
                borderRadius: '25%',
                width: '300px',
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: '5px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                ...wrapStyle,
                "& button": {
                    bgcolor: "primary.dark",
                    color: "primary.main",
                    zIndex: 1,
                    perspective: '1400px',
                    transformStyle: 'preserve-3d'
                }
            }}
        >
            <div
                style={{
                    width: 'fit-content',
                    boxShadow: 'inset 0px -5px 2px 1px #0000002e',
                    borderRadius: '50%'
                }}
            >
                {Array(3).fill(0).map((_v, i) => Svg3d(i, notInView
                    // , refAlreadyClicked.current
                ))}
            </div>
            <IconButton aria-label="replay" size="large"
                onClick={() => {
                    if (window) {
                        refAlreadyClicked.current = true
                        setReplay(!replay);
                        window.setTimeout(() => setReplay(true), 600);
                    }
                }}
            >
                <AutorenewIcon />
            </IconButton>
            <AnimationCycleTimeSlider duration={duration} setDuration={setDuration} />

        </Box>
    );

    function Svg3d(i: number, notInView: Boolean
        // , alreadyClicked: Boolean
    ) {
        return <motion.svg xmlns="http://www.w3.org/2000/svg"
            // xmlnsSvg="http://www.w3.org/2000/svg"
            width={`${svgWidth}`}
            height={`${svgHeight}`}
            viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
            version="1.1" id="svg5"
            // animate={(notInView && !alreadyClicked) ? false : ctlSvg}
            animate={ctlSvg}
            custom={i}
            style={{
                perspective: "inherit",
                transformStyle: 'inherit',
                originX: `${svgWidth / 2}px`,
                originY: `${svgHeight / 2}px`,
                z: 0,
                x: i * svgWidth,
                y: 0,
                rotateX: notInView ? 0 : 90,
                rotateZ: 0,
                rotateY: 0,
            }}
            key={`Svg3d-${i}`}
        >
            <defs id="defs2">
                <filter
                    style={{ colorInterpolation: 'sRGB' }}
                    id="filter1151" x="-0.45582721" y="-0.4736726" width="1.9116544" height="1.9473453">
                    <feGaussianBlur stdDeviation="3.1255278" id="feGaussianBlur1153" />
                </filter>
                <filter
                    style={{ colorInterpolation: 'sRGB' }}
                    id="filter1155" x="-0.13859057" y="-0.13880593" width="1.2771811" height="1.2776119">
                    <feGaussianBlur stdDeviation="2.9619389" id="feGaussianBlur1157" />
                </filter>
                <filter
                    style={{ colorInterpolation: 'sRGB' }}
                    id="filter1159" x="-0.29681605" y="-0.29780331" width="1.5936321" height="1.5956066">
                    <feGaussianBlur stdDeviation="5.301524" id="feGaussianBlur1161" />
                </filter>
                <filter
                    style={{ colorInterpolation: 'sRGB' }}
                    id="filter1163" x="-0.26693991" y="-0.26693991" width="1.5338798" height="1.5338798">
                    <feGaussianBlur stdDeviation="6.0001484" id="feGaussianBlur1165" />
                </filter>
                <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1173">
                    <circle
                        style={{
                            fill: '#808080',
                            fillRule: 'evenodd',
                            strokeWidth: '0',
                            strokeLinejoin: 'bevel',
                            strokeMiterlimit: '1',
                            paintOrder: 'markers stroke fill',
                        }}
                        id="circle1175" cx="65.255356" cy="82.075615" r="26.973152" />
                </clipPath>
                <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1177">
                    <circle
                        style={{
                            fill: '#808080',
                            fillRule: 'evenodd',
                            strokeWidth: '0',
                            strokeLinejoin: 'bevel',
                            strokeMiterlimit: '1',
                            paintOrder: 'markers stroke fill'
                        }}
                        id="circle1179" cx="65.255356" cy="82.075615" r="26.973152" />
                </clipPath>
                <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1181">
                    <circle
                        style={{
                            fill: '#808080',
                            fillRule: 'evenodd',
                            strokeWidth: '0',
                            strokeLinejoin: 'bevel',
                            strokeMiterlimit: '1',
                            paintOrder: 'markers stroke fill'
                        }}
                        id="circle1183" cx="65.255356" cy="82.075615" r="26.973152" />
                </clipPath>
                <clipPath clipPathUnits="userSpaceOnUse" id="clipPath3342">
                    <circle
                        style={{
                            fill: '#808080',
                            fillRule: 'evenodd',
                            strokeWidth: '0',
                            strokeLinejoin: 'bevel',
                            strokeMiterlimit: '1',
                            paintOrder: 'markers stroke fill'
                        }}
                        id="circle3344" cx="65.255356" cy="82.075615" r="26.973152" />
                </clipPath>
            </defs>
            <motion.g id="g3681"
                transform="rotate(-34.762659,-41.275229,115.7085)"
            >
                <motion.circle
                    animate={ctlSvgPartCircle as any}
                    custom={i}
                    style={{
                        display: 'inline',
                        fillOpacity: '1',
                        fillRule: 'evenodd',
                        strokeWidth: '0',
                        strokeLinejoin: 'bevel',
                        strokeMiterlimit: '1',
                        paintOrder: 'markers stroke fill',
                    }}
                    id="circle3662"
                    cx="103.01183" cy="18.51786"
                    transform="rotate(75.908628,43.6827,26.002807)"
                    r="26.973152"
                // fill={[ ...mapColors[ i ][ 0 ] ]}
                />
                <ellipse
                    style={{
                        display: 'inline',
                        mixBlendMode: 'normal',
                        fill: '#ffffff',
                        fillRule: 'evenodd',
                        strokeWidth: '4.00001',
                        strokeLinejoin: 'bevel',
                        strokeMiterlimit: '1',
                        paintOrder: 'markers stroke fill',
                        filter: 'url(#filter1151)',
                        transform: "rotate(41.145969,65.789289,82.075615)"
                    }}
                    id="ellipse3664"
                    cx="77.566917" cy="71.600143" rx="8.2281914" ry="7.9181981"
                    clipPath="url(#clipPath3342)" />
                <motion.g id="g3672"
                    animate={ctlSvgPartCircleClip as any}
                    transform="rotate(41.145969,65.789289,82.075615)"
                    style={{ mixBlendMode: 'luminosity', x: 0 }}
                >
                    <path id="path3666"
                        style={{
                            fill: '#666666',
                            stroke: 'none',
                            strokeWidth: '0.264583px',
                            strokeLinecap: 'butt',
                            strokeLinejoin: 'miter',
                            strokeOpacity: '1',
                            filter: 'url(#filter1163)'
                        }}
                        d="m 65.255448,55.10258 c -14.896765,6.7e-5 -26.972963,12.076265 -26.97303,26.97303 6.7e-5,14.896765 12.076265,26.97296 26.97303,26.97303 14.896766,-7e-5 26.972964,-12.076264 26.973031,-26.97303 -6.33e-4,-0.86868 -0.04323,-1.736822 -0.127641,-2.601391 C 71.867052,85.954101 63.165876,78.543298 67.68114,55.213684 66.874654,55.140255 66.06527,55.103182 65.255448,55.10258 Z" clipPath="url(#clipPath1181)" />
                    <path id="path3668"
                        style={{
                            fill: '#4d4d4d',
                            stroke: 'none',
                            strokeWidth: '0.264583px',
                            strokeLinecap: 'butt',
                            strokeLinejoin: 'miter',
                            strokeOpacity: '1',
                            filter: 'url(#filter1155)'
                        }}
                        d="m 53.435498,57.835746 c -9.269385,4.519003 -15.150984,13.927595 -15.15308,24.239864 6.7e-5,14.896765 12.076265,26.97296 26.97303,26.97303 10.376436,-6.2e-4 19.832559,-5.95347 24.319446,-15.309659 C 74.108566,90.538723 57.924987,89.177276 53.435498,57.835746 Z" clipPath="url(#clipPath1177)" />
                    <path id="path3670"
                        style={{
                            fill: '#1a1a1a',
                            stroke: 'none',
                            strokeWidth: '0.264583px',
                            strokeLinecap: 'butt',
                            strokeLinejoin: 'miter',
                            strokeOpacity: '1',
                            filter: 'url(#filter1159)'
                        }}
                        d="m 43.380298,66.3236 c -3.308579,4.586422 -5.091908,10.096754 -5.09788,15.75201 6.7e-5,14.896765 12.076265,26.97296 26.97303,26.97303 5.715454,-0.007 11.281107,-1.82838 15.894121,-5.20278 C 56.970423,99.422493 47.888198,84.468432 43.380298,66.3236 Z" clipPath="url(#clipPath1173)" />
                </motion.g>
            </motion.g>
        </motion.svg>;
    }
};

export default AnimateSvg3dBall

